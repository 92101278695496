<template>
  <div
    id="fullscreenElementMobile"
    class="mobileScreen-main-container"
    style="background: white; height:100dvh !important;"
  >
    <MobileHeader
      :testTimerData="testTimer"
      :hideAndShow="divTouchesHeader"
      :timeinsecondsfornotificationfunc="timeinsecondsfornotificationfunc"
    />

    <div
      :style="
        viewAllMarkForLater
          ? 'height: calc(100dvh - 150px)'
          : 'height: calc(100dvh - 194px)'
      "
      style="    overflow-y: auto;
    overflow-x: hidden;"
      @scroll="handleScroll($event)"
    >
      <div class=" m-3 d-flex align-items-center justify-content-between">
        <div>
          <div class="mobileScreen-TD-head">
            {{ testDetails.name }}
          </div>
          <div style="width:fit-content" class="mobileScreen-CT-attempts mt-1">
            Total Questions : {{ totalQuestionNocode }}
          </div>
        </div>

        <div
          v-if="testDetails.config.dispDuration"
          :style="timeinsecondsfornotificationfunc.style"
          :class="{ hide: divTouchesHeader }"
          class="mobileScreen-CT-timing-container d-flex align-items-center"
        >
          <div class="mr-2 pb-1">
            <img
              v-if="timeinsecondsfornotificationfunc.imageName == 'whitetimer'"
              src="../../assets/mobileScreenIcons/timer-stopwatch.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/mobileScreenIcons/timer-stopwatch-black.svg"
              alt=""
            />
          </div>
          <div>
            <span
              class="mobileScreen-CT-stopwatch-number"
              :style="timeinsecondsfornotificationfunc.style"
              >{{ testTimer }}</span
            >
          </div>
        </div>
      </div>
      <div class="sticky">
        <hr />

        <div class=" m-3 d-flex align-items-center justify-content-between">
          <div class="mobileScreen-TS-secName">
            {{ selectedSection.sectionName }}
          </div>

          <div>
            <span class="mr-2 mobileScreen-TS-secNumber">{{
              sectionId + 1
            }}</span>
            <span class="mobileScreen-TS-number-font">
              <span class="mr-2">of</span>
              <span>{{ this.sectionDetails.length }}</span>
            </span>
          </div>
        </div>

        <div
          class="mobileScreen-TS-mainSection mt-2 m-3 d-flex align-items-center w-100"
        >
          <div
            @click="prevSection()"
            v-if="sectionId >= 1"
            style="width: 9%;"
            class="pointer"
          >
            <img src="../../assets/mobileScreenIcons/left-arrow.svg" alt="" />
          </div>
          <div v-else style="width: 9%;" class="pointer">
            <img
              src="../../assets/mobileScreenIcons/left-arrow-disable.svg"
              alt=""
            />
          </div>
          <div style="width: 80%;" class="d-flex">
            <div
              v-for="(section, index) in sectionDetails"
              :key="index"
              class="mr-2 pointer"
              :class="
                sectionId == index
                  ? 'mobileScreen-TS-sections-selected'
                  : 'mobileScreen-TS-sections-unSelect'
              "
              :style="{ width: `${100 / sectionDetails.length}%` }"
              @click="selectSectionFunc(index, 0)"
            ></div>
          </div>
          <div
            @click="nextSection()"
            v-if="sectionId < sectionDetails.length - 1"
            style="width: 11%;"
            class="pointer"
          >
            <img src="../../assets/mobileScreenIcons/right-arrow.svg" alt="" />
          </div>
          <div v-else style="width: 11%;" class="pointer">
            <img
              src="../../assets/mobileScreenIcons/right-arrow-disable.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <hr />

      <div class=" m-3 d-flex align-items-center justify-content-between">
        <div class="mobileScreen-TS-secName pointer">
          Questions answered
        </div>
        <div>
          <span class="mobileScreen-TS-secNumber">{{
            totalanswered.attemptedQuestions || 0
          }}</span>
          <span class="mobileScreen-TS-number-font">
            <span>/</span>
            <span>{{ totalQuestionNocode }}</span>
          </span>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="mr-1 ml-3" style="width: 3%;">
          <img src="../../assets/mobileScreenIcons/TS-circle.svg" alt="" />
        </div>
        <div class="mobileScreen-TS-progress" style="width: 94%;">
          <b-progress
            :value="totalanswered.attemptedQuestions"
            class="w-100"
            :max="totalQuestionNocode"
          ></b-progress>
        </div>
        <div class="mr-3 ml-1" style="width: 3%;">
          <img src="../../assets/mobileScreenIcons/TS-flag.svg" alt="" />
        </div>
      </div>

      <hr />

      <div
        v-if="!viewAllMarkForLater"
        @click="viewAllMarkForLaterQuestions()"
        class="mt-2 d-flex align-items-center justify-content-between m-3"
      >
        <div class="mobileScreen-TS-mark-font">
          Marked for later questions
        </div>

        <div class="mobileScreen-TS-view-font pointer">
          View
        </div>
      </div>
      <div
        v-else
        class="mt-2 d-flex align-items-center justify-content-between m-3"
      >
        <div
          @click="viewAllMarkForLater = false"
          class="mobileScreen-TS-mark-font"
        >
          <img class="mr-1" src="../../assets/mobile-newUI-imgs/mfl-left-arrow.svg"/> Back
        </div>

        <div class="mobileScreen-TS-view-font pointer"></div>
      </div>

      <hr />

      <!-- design for cards starts from here -->

      <!-- MCQ card -->
      <div v-if="!viewAllMarkForLater && !viewUnAnswered">
        <div
          v-if="returnCurrentQuestion.questionKey.slice(0, 4) == 'MCQU'"
          class="mobileScreen-TS-cards m-3"
        >
          <div class="d-flex align-items-center m-3 justify-content-between">
            <div class="d-flex align-items-center">
              <div class="mobileScreen-TS-bluecard mr-3">
                MCQ
              </div>

              <div class="mobileScreen-TS-bluecard">
                {{ returnCurrentQuestion.questionBody.marks }} Mark
              </div>
            </div>
            <div
              v-if="returnCurrentQuestion.questionBody.config.config.required"
              class="mobileScreen-TS-required-card"
            >
              <img
                src="../../assets/mobileScreenIcons/required-icon.svg"
                alt=""
              />
              <span class="ml-2">Required</span>
            </div>
          </div>
          <div class="m-3">
            <div class="d-flex">
              <div class="mobileScreen-TS-cards-head">
                Q{{ currentQuestionIndex + 1 }}. &nbsp;
              </div>
              <div
                v-html-safe="returnCurrentQuestion.questionBody.question"
                class="mobileScreen-TS-cards-head"
              ></div>
            </div>
            <div
              v-if="returnCurrentQuestion.questionBody.questionMedia != null"
              class="mb-3"
            >
              <div
                v-if="
                  isImage(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <img
                  class="qb-image-preview"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  alt=""
                />
              </div>
              <div
                v-if="
                  isAudio(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <Media
                  :kind="'audio'"
                  :isMuted="false"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  :autoplay="false"
                  :controls="true"
                  :loop="true"
                  class="mcqdatacard-audio-2"
                ></Media>
              </div>
              <div
                v-if="
                  isVideo(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <Media
                  :kind="'video'"
                  :isMuted="false"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  :autoplay="false"
                  :controls="true"
                  :loop="true"
                  class="qb-image-preview"
                ></Media>
              </div>
            </div>
            <div class="mt-2 mobileScreen-TS-radios">
              <b-form-radio-group
                @change="selectedOption()"
                value-field="value"
                text-field="value"
                name="some-radios"
                v-model="returnCurrentQuestion.answer"
                :options="returnCurrentQuestion.questionBody.options"
                class="mobsec-ques-style radio-btns"
              ></b-form-radio-group>
            </div>
          </div>
          <hr />

          <div class="d-flex align-items-center justify-content-between m-3">
            <div class="mobileScreen-TS-mark-later">
              Mark for later
            </div>
            <div class="mobileScreen-SC-switch">
              <b-form-checkbox
                v-model="returnCurrentQuestion.answerStatus.later"
                @change="markforLater()"
                switch
                size="sm"
                class="visibility-unselected-profile-switch"
              ></b-form-checkbox>
            </div>
          </div>
        </div>

        <!-- descriptive card -->
        <div
          v-if="returnCurrentQuestion.questionKey.slice(0, 4) == 'LONG'"
          class="mobileScreen-TS-cards m-3"
        >
          <div class="d-flex align-items-center m-3 justify-content-between">
            <div class="d-flex align-items-center">
              <div class="mobileScreen-TS-bluecard mr-3">
                Descriptive
              </div>

              <div class="mobileScreen-TS-bluecard">
                {{ returnCurrentQuestion.questionBody.marks }} Mark
              </div>
            </div>
            <div
              v-if="returnCurrentQuestion.questionBody.config.config.required"
              class="mobileScreen-TS-required-card"
            >
              <img
                src="../../assets/mobileScreenIcons/required-icon.svg"
                alt=""
              />
              <span class="ml-2">Required</span>
            </div>
          </div>
          <div class="m-3">
            <div class="d-flex">
              <div class="mobileScreen-TS-cards-head">
                Q{{ currentQuestionIndex + 1 }}.&nbsp;
              </div>
              <div
                v-html-safe="returnCurrentQuestion.questionBody.question"
                class="mobileScreen-TS-cards-head"
              ></div>
            </div>
            <div
              v-if="returnCurrentQuestion.questionBody.questionMedia != null"
              class="mb-3"
            >
              <div
                v-if="
                  isImage(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <img
                  class="qb-image-preview"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  alt=""
                />
              </div>
              <div
                v-if="
                  isAudio(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <Media
                  :kind="'audio'"
                  :isMuted="false"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  :autoplay="false"
                  :controls="true"
                  :loop="true"
                  class="mcqdatacard-audio-2"
                ></Media>
              </div>
              <div
                v-if="
                  isVideo(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <Media
                  :kind="'video'"
                  :isMuted="false"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  :autoplay="false"
                  :controls="true"
                  :loop="true"
                  class="qb-image-preview"
                ></Media>
              </div>
            </div>
            <div class="mt-2">
              <b-form-textarea
                @keyup="textSearch($event)"
                v-model.trim="returnCurrentQuestion.answer"
                class="mobileScreen-description-card"
                id="textarea"
                placeholder="Enter a description"
                rows="5"
                no-resize
                @copy="preventCopyPaste"
                @cut="preventCopyPaste"
                @paste="preventCopyPaste"
              ></b-form-textarea>
            </div>
          </div>
          <hr />

          <div class="d-flex align-items-center justify-content-between m-3">
            <div class="mobileScreen-TS-mark-later">
              Mark for later
            </div>
            <div class="mobileScreen-SC-switch">
              <b-form-checkbox
                v-model="returnCurrentQuestion.answerStatus.later"
                @change="markforLater()"
                switch
                size="sm"
                class="visibility-unselected-profile-switch"
              ></b-form-checkbox>
            </div>
          </div>
        </div>

        <!-- match the following card -->
        <div
          v-if="returnCurrentQuestion.questionKey.slice(0, 4) == 'MAFO'"
          class="mobileScreen-TS-cards m-3"
        >
          <div class="d-flex align-items-center m-3 justify-content-between">
            <div class="d-flex align-items-center">
              <div class="mobileScreen-TS-bluecard mr-3">
                Match the following
              </div>

              <div class="mobileScreen-TS-bluecard">
                {{ returnCurrentQuestion.questionBody.marks }} Marks
              </div>
            </div>
            <div
              v-if="returnCurrentQuestion.questionBody.config.config.required"
              class="mobileScreen-TS-required-card"
            >
              <img
                src="../../assets/mobileScreenIcons/required-icon.svg"
                alt=""
              />
              <span class="ml-2">Required</span>
            </div>
          </div>
          <div class="m-3">
            <div class="d-flex">
              <div class="mobileScreen-TS-cards-head">
                Q{{ currentQuestionIndex + 1 }}.&nbsp;
              </div>
              <div
                v-html-safe="returnCurrentQuestion.questionBody.question"
                class="mobileScreen-TS-cards-head"
              ></div>
            </div>
            <div
              v-if="returnCurrentQuestion.questionBody.questionMedia != null"
              class="mb-3"
            >
              <div
                v-if="
                  isImage(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <img
                  class="qb-image-preview"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  alt=""
                />
              </div>
              <div
                v-if="
                  isAudio(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <Media
                  :kind="'audio'"
                  :isMuted="false"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  :autoplay="false"
                  :controls="true"
                  :loop="true"
                  class="mcqdatacard-audio-2"
                ></Media>
              </div>
              <div
                v-if="
                  isVideo(returnCurrentQuestion.questionBody.questionMediaType)
                "
                class="d-flex align-items-center justify-content-center"
              >
                <Media
                  :kind="'video'"
                  :isMuted="false"
                  :src="returnCurrentQuestion.questionBody.questionMedia"
                  :autoplay="false"
                  :controls="true"
                  :loop="true"
                  class="qb-image-preview"
                ></Media>
              </div>
            </div>
            <div class="mt-2 mobileScreen-TS-mtf-innerCard">
              <div class="d-flex align-items-center w-100">
                <div
                  style="width: 50%;"
                  class="mobileScreen-TS-mtf-innerCard-head"
                >
                  Questions
                </div>
                <div
                  style="width: 50%;"
                  class="mobileScreen-TS-mtf-innerCard-head"
                >
                  Answers
                </div>
              </div>
              <div class="d-flex align-items-center w-100">
                <div style="width: 50%;">
                  <div
                    v-for="(option, index) in returnCurrentQuestion.questionBody
                      .answerKey"
                    :key="index"
                  >
                    <div class="mobileScreen-TS-mtf-subCard text-break w-100">
                      {{ option.sideA }}
                    </div>
                  </div>
                </div>

                <div style="width: 50%;">
                  <draggable
                    v-model="returnCurrentQuestion.answer"
                    :move="handleMove"
                    @end="handleDragEnd"
                  >
                    <transition-group tag="div" class="grid" name="grid">
                      <div
                        v-for="(item, index) in returnCurrentQuestion.answer"
                        :key="index"
                        class="d-flex justify-content-between mobileScreen-TS-mtf-subCard pointer"
                      >
                        <div class="ml-2 text-break w-75">{{ item }}</div>
                        <div>
                          <img
                            src="../../assets/icons/testscreen/sixdotsmobile.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <img
                src="../../assets/mobileScreenIcons/mtf-info-icon.svg"
                alt=""
              />
              <span class="ml-3 mobileScreen-TS-mtf-drag-text"
                >Drag to Match the following</span
              >
            </div>
          </div>
          <hr />

          <div class="d-flex align-items-center justify-content-between m-3">
            <div class="mobileScreen-TS-mark-later">
              Mark for later
            </div>
            <div class="mobileScreen-SC-switch">
              <b-form-checkbox
                v-model="returnCurrentQuestion.answerStatus.later"
                @change="markforLater()"
                switch
                size="sm"
                class="visibility-unselected-profile-switch"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div v-if="viewAllMarkForLater || viewUnAnswered">
        <div
          v-for="(item, index) in allMarkForLaterandUnAnawerQuestions"
          :key="index"
        >
          <div
            v-if="item.questionKey.slice(0, 4) == 'MCQU'"
            class="mobileScreen-TS-cards m-3"
          >
            <div class="d-flex align-items-center m-3 justify-content-between">
              <div class="d-flex align-items-center">
                <div class="mobileScreen-TS-bluecard mr-3">
                  MCQ
                </div>

                <div class="mobileScreen-TS-bluecard">
                  {{ item.questionBody.marks }} Mark
                </div>
              </div>
              <div
                v-if="item.questionBody.config.config.required"
                class="mobileScreen-TS-required-card"
              >
                <img
                  src="../../assets/mobileScreenIcons/required-icon.svg"
                  alt=""
                />
                <span class="ml-2">Required</span>
              </div>
            </div>
            <div class="m-3">
              <div class="d-flex">
                <div class="mobileScreen-TS-cards-head">
                  Q{{ index + 1 }}.&nbsp;
                </div>
                <div
                  v-html-safe="item.questionBody.question"
                  class="mobileScreen-TS-cards-head"
                ></div>
              </div>
              <div class="mt-2 mobileScreen-TS-radios">
                <b-form-radio-group
                  @change="selectedOptionMark(item)"
                  value-field="value"
                  text-field="value"
                  :name="'some-radios-' + index"
                  v-model="item.answer"
                  :options="item.questionBody.options"
                  class="mobsec-ques-style radio-btns"
                ></b-form-radio-group>
              </div>
            </div>
            <hr />

            <div class="d-flex align-items-center justify-content-between m-3">
              <div class="mobileScreen-TS-mark-later">
                Mark for later
              </div>
              <div
                class="mobileScreen-SC-switch d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox
                  v-model="item.answerStatus.later"
                  @change="markforLaterView(item)"
                  switch
                  size="sm"
                  class="visibility-unselected-profile-switch"
                ></b-form-checkbox>
              </div>
            </div>
          </div>

          <!-- descriptive card -->
          <div
            v-if="item.questionKey.slice(0, 4) == 'LONG'"
            class="mobileScreen-TS-cards m-3"
          >
            <div class="d-flex align-items-center m-3 justify-content-between">
              <div class="d-flex align-items-center">
                <div class="mobileScreen-TS-bluecard mr-3">
                  Descriptive
                </div>

                <div class="mobileScreen-TS-bluecard">
                  {{ item.questionBody.marks }} Mark
                </div>
              </div>
              <div
                v-if="item.questionBody.config.config.required"
                class="mobileScreen-TS-required-card"
              >
                <img
                  src="../../assets/mobileScreenIcons/required-icon.svg"
                  alt=""
                />
                <span class="ml-2">Required</span>
              </div>
            </div>
            <div class="m-3">
              <div class="d-flex">
                <div class="mobileScreen-TS-cards-head">
                  Q{{ index + 1 }}.&nbsp;
                </div>
                <div
                  v-html-safe="item.questionBody.question"
                  class="mobileScreen-TS-cards-head"
                ></div>
              </div>

              <div class="mt-2">
                <b-form-textarea
                  @keyup="textSearchview(item, $event)"
                  v-model.trim="item.answer"
                  class="mobileScreen-description-card"
                  id="textarea"
                  placeholder="Enter a description"
                  rows="5"
                  no-resize
                  @copy="preventCopyPaste"
                  @cut="preventCopyPaste"
                  @paste="preventCopyPaste"
                ></b-form-textarea>
              </div>
            </div>
            <hr />

            <div class="d-flex align-items-center justify-content-between m-3">
              <div class="mobileScreen-TS-mark-later">
                Mark for later
              </div>
              <div
                class="mobileScreen-SC-switch d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox
                  v-model="item.answerStatus.later"
                  @change="markforLaterView(item)"
                  switch
                  size="sm"
                  class="visibility-unselected-profile-switch"
                ></b-form-checkbox>
              </div>
            </div>
          </div>

          <!-- match the following card -->
          <div
            v-if="item.questionKey.slice(0, 4) == 'MAFO'"
            class="mobileScreen-TS-cards m-3"
          >
            <div class="d-flex align-items-center m-3 justify-content-between">
              <div class="d-flex align-items-center">
                <div class="mobileScreen-TS-bluecard mr-3">
                  Match the following
                </div>

                <div class="mobileScreen-TS-bluecard">
                  {{ item.questionBody.marks }} Marks
                </div>
              </div>
              <div
                v-if="item.questionBody.config.config.required"
                class="mobileScreen-TS-required-card"
              >
                <img
                  src="../../assets/mobileScreenIcons/required-icon.svg"
                  alt=""
                />
                <span class="ml-2">Required</span>
              </div>
            </div>
            <div class="m-3">
              <div class="d-flex">
                <div class="mobileScreen-TS-cards-head">
                  Q{{ index + 1 }}.&nbsp;
                </div>
                <div
                  v-html-safe="item.questionBody.question"
                  class="mobileScreen-TS-cards-head"
                ></div>
              </div>
              <div class="mt-2 mobileScreen-TS-mtf-innerCard">
                <div class="d-flex align-items-center w-100">
                  <div
                    style="width: 50%;"
                    class="mobileScreen-TS-mtf-innerCard-head"
                  >
                    Questions
                  </div>
                  <div
                    style="width: 50%;"
                    class="mobileScreen-TS-mtf-innerCard-head"
                  >
                    Answers
                  </div>
                </div>
                <div class="d-flex align-items-start w-100">
                  <div style="width: 50%;">
                    <div
                      v-for="(option, index) in item.questionBody.answerKey"
                      :key="index"
                    >
                      <div
                        class="mobileScreen-TS-mtf-subCard text-break w-7100"
                      >
                        {{ option.sideA }}
                      </div>
                    </div>
                  </div>

                  <div style="width: 50%;">
                    <draggable
                      v-model="item.answer"
                      :move="handleMove"
                      @end="handleDragEndMark(item)"
                    >
                      <transition-group tag="div" class="grid" name="grid">
                        <div
                          v-for="(item, index) in item.answer"
                          :key="index"
                          class="d-flex justify-content-between mobileScreen-TS-mtf-subCard pointer"
                        >
                          <div class="ml-2 text-break w-75">{{ item }}</div>
                          <div>
                            <img
                              src="../../assets/icons/testscreen/sixdotsmobile.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <img
                  src="../../assets/mobileScreenIcons/mtf-info-icon.svg"
                  alt=""
                />
                <span class="ml-3 mobileScreen-TS-mtf-drag-text"
                  >Drag to Match the following</span
                >
              </div>
            </div>
            <hr />

            <div class="d-flex align-items-center justify-content-between m-3">
              <div class="mobileScreen-TS-mark-later">
                Mark for later
              </div>
              <div
                class="mobileScreen-SC-switch d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox
                  v-model="item.answerStatus.later"
                  @change="markforLaterView(item)"
                  switch
                  size="sm"
                  class="visibility-unselected-profile-switch"
                ></b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="allMarkForLaterandUnAnawerQuestions.length <= 0"
          class=" mobileScreen-TS-secName text-center"
        >
          No Questions
        </div>
      </div>

      <!-- pagination starts from here -->
    </div>
    <div
      v-if="!viewAllMarkForLater && !viewUnAnswered"
      class="m-pagination d-flex align-items-center justify-content-center"
    >
      <div
        @click="prevQuetion()"
        v-if="currentQuestionIndex != 0"
        class="mobileScreen-quest-icon mt-1 pointer"
      >
        <img src="../../assets/mobileScreenIcons/left-arrow.svg" alt="" />
      </div>
      <div v-else class="mobileScreen-quest-icon mt-1 pointer">
        <img
          src="../../assets/mobileScreenIcons/left-arrow-disable.svg"
          alt=""
        />
      </div>
      <div class="mt-1 mobile-login-sub-font mobileScreen-quest-div ml-2 mr-2">
        Question
        <span class="ml-1 mr-1">
          {{ this.currentQuestionIndex + 1 }}
        </span>
        <span class="mr-1">
          /
        </span>
        <span>
          {{ selectedSection.sectionResponse.length }}
        </span>
      </div>
      <div
        @click="nextQuetion()"
        v-if="
          currentQuestionIndex != selectedSection.sectionResponse.length - 1
        "
        class="mobileScreen-quest-icon mt-1 pointer"
      >
        <img src="../../assets/mobileScreenIcons/right-arrow.svg" alt="" />
      </div>
      <div v-else class="mobileScreen-quest-icon mt-1 pointer">
        <img
          src="../../assets/mobileScreenIcons/right-arrow-disable.svg"
          alt=""
        />
      </div>
    </div>
    <div
      class="mobileScreen-TD-footer mt-2 d-flex align-items-center justify-content-center bluebtns"
    >
      <b-button
        v-if="testDetails.config.includeEndTest"
        @click="endTestPost()"
        style="width: 100%;"
        class="mobileScreen-startBtn"
        >Finish Test</b-button
      >
    </div>
    <div v-if="leaveTestMobile" class="testscreen-custom-modal">
      <div class="testscreen-modal-content">
        <div
          class="d-flex align-items-center justify-content-center flex-column"
        >
          <div>
            <img
              src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
              alt=""
            />
          </div>

          <div class="mt-4 mobileScreen-modal-head">
            Alert!
          </div>

          <div class="mt-3 mobileScreen-modal-subhead text-center">
            Are you sure you want to leave the test?
          </div>

          <div
            class="mt-4 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
          >
            <div class="w-50">
              <b-button
                @click="leaveTestMobile = false"
                class="mobileScreen-startBtn w-100"
                >Cancel</b-button
              >
            </div>
            <div class="w-50">
              <b-button
                @click="leaveTestMobilefunc()"
                class="mobileScreen-leaveBtn mt-3 w-100"
                >Leave Test</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="latermodal" class="testscreen-custom-modal">
      <div class="mobile-testscreen-modal-content">
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
              alt=""
            />
          </div>
          <div
            class="mobileScreen-modal-head mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div
            class="mobileScreen-modal-subhead mt-4"
            style="font-size: 16px;"
            v-html-safe="laterModalText"
          ></div>
          <div
            class="mt-4 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
          >
            <div class="w-50">
              <b-button
                class="mobileScreen-startBtn w-100"
                @click="finishtestlater()"
                >Finish Test</b-button
              >
            </div>
            <div class="w-50">
              <b-button
                @click="latermodal = false"
                class="mobileScreen-leaveBtn mt-3 w-100"
                >Cancel</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cantendtest" class="testscreen-custom-modal">
      <div class="mobile-testscreen-modal-content">
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
              alt=""
            />
          </div>
          <div
            class="mobileScreen-modal-head mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div
            class="mobileScreen-modal-subhead mt-4"
            style="font-size: 16px;"
            v-html-safe="requiredText"
          ></div>

          <div class="mt-4 mobileScreen-TS-secName text-center w-50">
            <b-button
              class="mobileScreen-startBtn w-100"
              @click="cantendtest = false"
              >Okay</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFullScreen" class="testscreen-custom-modal">
      <div class="mobile-testscreen-modal-content">
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
              alt=""
            />
          </div>
          <div
            class="mobileScreen-modal-head mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div class="mobileScreen-modal-subhead mt-4">
            This test requires full screen mode.
          </div>

          <div class="mt-4 mobileScreen-TS-secName text-center w-50">
            <b-button
              class="mobileScreen-startBtn w-100"
              @click="(showFullScreen = false), fullScreenMode()"
              >Okay</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isImage, isVideo, isAudio } from "../../checkFileType";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import testScreen from "../../mixins/testScreen";
import MobileHeader from "./MobileHeader.vue";
export default {
  name: "MsystemCheck",
  components: {
    MobileHeader,
    draggable,
  },
  data() {
    return {
      showFullScreen: false,
      leaveTestMobile: false,
      sections: [1, 2, 5, 3, 6, 9, 8, 5],
      selectSection: false,
      viewUnAnswered: false,
      value: 33.333333333,
      viewAllMarkForLater: false,
      max: 50,
      divTouchesHeader: false,
      match: 4,
      viewType: "later",
      futureIndex: 0,
      movingIndex: 0,
      allMarkForLaterandUnAnawerQuestions: [],
    };
  },
  mixins: [testScreen],
  computed: {
    ...mapGetters("testVUEX", {
      questionData: "getQuestionData",
      sectionDetails: "getSectionDetails",
      selectedSection: "getSelectedSection",
    }),
    ...mapGetters("sectionVUEX",{
      uuid: "getUUID"
    }),
    totalQuestionNocode() {
      let total = 0;
      this.sectionDetails.map((data) => {
        if (!data.codingSection) total = data.sectionResponse.length + total;
      });
      return total;
    },
    returnCurrentQuestion() {
      return this.selectedSection.sectionResponse[this.currentQuestionIndex];
    },
  },
  mounted() {
    if (!localStorage.getItem("testFinished")) {
      console.log(this.selectedSection.sectionResponse);
      document.addEventListener(
        "fullscreenchange",
        this.handleFullScreenChange
      );
      document.addEventListener(
        "mozfullscreenchange",
        this.handleFullScreenChange
      );
      document.addEventListener(
        "webkitfullscreenchange",
        this.handleFullScreenChange
      );
      document.addEventListener(
        "msfullscreenchange",
        this.handleFullScreenChange
      );
      this.showFullScreen = true;
      console.log(
        this.sectionId,
        this.sectionDetails,
        this.currentQuestionIndex
      );
    }
  },
  created() {
    if (localStorage.getItem("testFinished")) {
      this.$router.push({ name: "TestClosed" });
    } else {
      console.log(this.testDetails);
      this.selectSectionFunc(this.sectionId, this.currentQuestionIndex);
      this.currentQuestion(this.currentQuestionIndex);
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/testCount", payload);
    }
  },
  methods: {
    fullScreenMode() {
      const fullscreenElement = document.getElementById(
        "fullscreenElementMobile"
      );
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        // Firefox
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        fullscreenElement.webkitRequestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        // IE/Edge
        fullscreenElement.msRequestFullscreen();
      }
    },
    handleFullScreenChange() {
      console.log("screen");
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        return;
      } else {
        this.leaveTestMobile = false;
        this.latermodal = false;
        this.cantendtest = false;
        this.showFullScreen = true;
      }
    },
    saveOnview(data) {
      console.log(data, this.viewAllMarkForLater);
      if (this.viewUnAnswered) {
        this.viewUnAnsweredQuestions();
      }
      if (this.viewUnAnswered) {
        this.viewUnAnsweredQuestions();
      }
    },
    isImage,
    isVideo,
    isAudio,
    viewUnAnsweredQuestions() {
      this.allMarkForLaterandUnAnawerQuestions = [];
      this.viewType = "UnAnswered";
      this.viewUnAnswered = !this.viewUnAnswered;
      this.viewAllMarkForLater = false;
      this.selectedSection.sectionResponse.map((data) => {
        if (this.viewType == "UnAnswered") {
          if (!data.answerStatus.attempted) {
            this.allMarkForLaterandUnAnawerQuestions.push(
              JSON.parse(JSON.stringify(data))
            );
          }
        }
      });
      console.log(this.allMarkForLaterandUnAnawerQuestions);
    },
    viewAllMarkForLaterQuestions() {
      this.allMarkForLaterandUnAnawerQuestions = [];
      this.viewType = "later";
      this.viewAllMarkForLater = true;
      this.viewUnAnswered = false;
      this.selectedSection.sectionResponse.map((data) => {
        if (this.viewType == "later") {
          if (data.answerStatus.later) {
            this.allMarkForLaterandUnAnawerQuestions.push(
              JSON.parse(JSON.stringify(data))
            );
          }
        }
      });
      console.log(this.allMarkForLaterandUnAnawerQuestions);
    },
    nextSection() {
      this.viewAllMarkForLater = false;
      this.viewUnAnswered = false;
      let section = this.sectionId + 1;
      this.$store.commit("sectionVUEX/prevnextbtnstatus", section);
      this.$store.commit(
        "testVUEX/saveSelectedSection",
        this.sectionDetails[section]
      );
      this.$store.commit("sectionVUEX/currentQuestionIndex", 0);
    },
    prevSection() {
      this.viewAllMarkForLater = false;
      this.viewUnAnswered = false;
      let section = this.sectionId - 1;
      this.$store.commit("sectionVUEX/prevnextbtnstatus", section);
      this.$store.commit(
        "testVUEX/saveSelectedSection",
        this.sectionDetails[section]
      );
      this.$store.commit("sectionVUEX/currentQuestionIndex", 0);
    },
    handleScroll(event) {
      if (event.target.scrollTop >= 16) {
        this.divTouchesHeader = true;
      } else {
        this.divTouchesHeader = false;
      }
    },
    leaveTestMobilefunc() {
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },
    leavetestFunc() {
      this.leaveTestMobile = true;
    },
    handleMove(e) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      return false; // disable sort
    },
    handleDragEnd() {
      console.log(this.returnCurrentQuestion.answer);
      this.futureItem = this.returnCurrentQuestion.answer[this.futureIndex];
      this.movingItem = this.returnCurrentQuestion.answer[this.movingIndex];
      const _items = Object.assign([], this.returnCurrentQuestion.answer);
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;
      this.returnCurrentQuestion.answer = _items;
      this.returnCurrentQuestion.answerStatus.unattempted = false;
      this.returnCurrentQuestion.answerStatus.attempted = true;
      const payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.returnCurrentQuestion.questionKey,
        answer: this.returnCurrentQuestion.answer,
        answerStatus: this.returnCurrentQuestion.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    handleDragEndMark(data) {
      this.futureItem = data.answer[this.futureIndex];
      this.movingItem = data.answer[this.movingIndex];
      const _items = Object.assign([], data.answer);
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;
      data.answer = _items;
      data.answerStatus.unattempted = false;
      data.answerStatus.attempted = true;
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: data.questionKey,
        answer: data.answer,
        answerStatus: data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    textSearch() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveCurrentAnswer();
      }, 500);
    },
    textSearchview(data) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveCurrentAnswerview(data);
      }, 500);
    },
    saveCurrentAnswerview(data) {
      if (data.answer == "") {
        data.answerStatus.active = true;
        data.answerStatus.unattempted = true;
        data.answerStatus.attempted = false;
      } else {
        data.answerStatus.active = true;
        data.answerStatus.unattempted = false;
        data.answerStatus.attempted = true;
      }
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: data.questionKey,
        answer: data.answer,
        answerStatus: data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    saveCurrentAnswer() {
      if (this.returnCurrentQuestion.answer == "") {
        this.returnCurrentQuestion.answerStatus.active = true;
        this.returnCurrentQuestion.answerStatus.unattempted = true;
        this.returnCurrentQuestion.answerStatus.attempted = false;
      } else {
        this.returnCurrentQuestion.answerStatus.active = true;
        this.returnCurrentQuestion.answerStatus.unattempted = false;
        this.returnCurrentQuestion.answerStatus.attempted = true;
      }
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.returnCurrentQuestion.questionKey,
        answer: this.returnCurrentQuestion.answer,
        answerStatus: this.returnCurrentQuestion.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    preventCopyPaste(event) {
      event.preventDefault();
    },
    prevQuetion() {
      let currentQuestionIndex = this.currentQuestionIndex - 1;
      this.$store.commit(
        "sectionVUEX/currentQuestionIndex",
        currentQuestionIndex
      );
    },
    nextQuetion() {
      let currentQuestionIndex = this.currentQuestionIndex + 1;
      this.$store.commit(
        "sectionVUEX/currentQuestionIndex",
        currentQuestionIndex
      );
    },
    selectSectionFunc(index, questionIndex) {
      this.viewAllMarkForLater = false;
      this.viewUnAnswered = false;
      this.$store.commit("sectionVUEX/prevnextbtnstatus", index);
      this.$store.commit(
        "testVUEX/saveSelectedSection",
        this.sectionDetails[index]
      );
      this.$store.commit("sectionVUEX/currentQuestionIndex", questionIndex);
    },
    currentQuestion(index) {
      this.$store.commit("sectionVUEX/currentQuestionIndex", index);
    },
    selectedOption() {
      this.returnCurrentQuestion.answerStatus.unattempted = false;
      this.returnCurrentQuestion.answerStatus.attempted = true;
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.returnCurrentQuestion.questionKey,
        answer: this.returnCurrentQuestion.answer,
        answerStatus: this.returnCurrentQuestion.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    selectedOptionMark(item) {
      console.log(item);
      item.answerStatus.unattempted = false;
      item.answerStatus.attempted = true;
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: item.questionKey,
        answer: item.answer,
        answerStatus: item.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    markforLater() {
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.returnCurrentQuestion.questionKey,
        answer: this.returnCurrentQuestion.answer,
        answerStatus: this.returnCurrentQuestion.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    markforLaterView(item) {
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: item.questionKey,
        answer: item.answer,
        answerStatus: item.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
  },
};
</script>

<style scoped>
.mobileScreen-TS-progress .progress {
  height: 9px !important;
  border: none;
  background-color: #d9d9d9 !important;
}

.mobileScreen-TS-progress .progress-bar {
  border-radius: 20px !important;
  background: linear-gradient(
    90deg,
    #2b5bba 8.16%,
    rgba(38, 97, 212, 0.49) 80.61%
  ) !important;
}

.testscreen-custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 1500;
}

.testscreen-modal-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 1.5%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  height: 400px;
  overflow: auto;
  padding: 10px;
  position: relative;
  width: 650px;
}
</style>
