export function isImage(filename) {
    switch (filename.toLowerCase()) {
      case "jpg":
      case "gif":
      case "jpeg":
      case "bmp":
      case "png":
        //etc
        return true;
    }
    return false;
  }
  export function isVideo(filename) {
    switch (filename.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
    }
    return false;
  }
  export function isAudio(filename) {
    switch (filename.toLowerCase()) {
      case "pcm":
      case "wav":
      case "aiff":
      case "mp3":
      case "ogg":
      case "aac":
      case "wma":
      case "flac":
      case "alac":
        // etc
        return true;
    }
    return false;
  }
  