import { io } from 'socket.io-client';
class SocketioService {
  socket;
  setupSocketConnection() {
    this.socket = io("https://browsertimer.simplifycv.com"); // development 
    this.socket = io("https://browsertimer.simplifymycareer.com"); // Staging
  }
  disconnect() {
    if (this.socket) {
        this.socket.disconnect();
        this.socket.emit("message","pause timer")
    }
}
}
export default new SocketioService();