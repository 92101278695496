import { mapGetters } from "vuex";
import { io } from "socket.io-client";
import postMessageUrl from "./postMessageUrl";

const socket = io("wss://mediaserver.hire3x.com", {
  reconnectionDelayMax: 10000,
});
import {
  stopTestAgent,
  endTestPost,
  postMarking,
  posttimer,
} from "../apiFunction";
import SocketioService from "../services/socketio.service";

const testScreen = {
  name: "TestScreens",
  mixins: [postMessageUrl],
  data() {
    return {
      socketInterval: null,
      timerFix: null,
      laterModalText: "",
      Loaderstatus: false,
      cantendtest: false,
      endTestRes: false,
      testtimerInterval: null,
      timerEnd: false,
      latermodal: false,
      timeinsecondsfornotification: null,
      force: 0,
      requiredText:"",
    };
  },
  computed: {
    ...mapGetters("testVUEX", {
      questionData: "getQuestionData",
      sectionDetails: "getSectionDetails",
      selectedSection: "getSelectedSection",
      totalanswered: "gettotalanswered",
    }),
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      userID: "getUserID",
      uuid: "getUUID",
      nextBtn: "getNextBtn",
      prevBtn: "getPrevBtn",
      licenseKey: "getlicensekey",
      sectionId: "getsectionId",
      testtimeleft: "gettesttimeleft",
      testtaken: "gettesttaken",
      testTimer: "gettesttimeleftintimeformat",
      currentQuestionIndex:"getcurrentQuestionIndex"
    }),
    ...mapGetters("questionVUEX", {
      noOfQues: "getNoOfQues",
      lastQuestionIndex: "getLastQuestionIndex",
      noPage: "getNoPage",
      noOfPages: "getNoOfPages",
      pageItem: "getPageItem",
    }),
    timeinsecondsfornotificationfunc() {
      if (this.testDetails.config.includeTimeNotifics) {
        let totaltime = Number(this.testDetails.config.testDuration) * 60;
        if (this.timeinsecondsfornotification <= (5 / 100) * totaltime) {
          return { style: "background: #f04e4e;color: #000000;", imageName: "" };
        } else if (
          this.timeinsecondsfornotification <=
          (25 / 100) * totaltime
        ) {
          return { style: "background: orange;color:  #000000;", imageName: "" };
        } else if (
          this.timeinsecondsfornotification <=
          (50 / 100) * totaltime
        ) {
          return { style: "background: yellow;color:  #000000;", imageName: "" };
        } else {
          return { style: "background: #4ef085;color:  #000000;", imageName: "" };
        }
      } else {
        return { style: "", imageName: "whitetimer" };
      }
    },
  },
  mounted() {
    SocketioService.setupSocketConnection();
    this.Timer();
  },
  created() {
    this.timeinsecondsfornotification = localStorage.getItem("timer");
  },
  beforeDestroy() {
    clearInterval(this.socketInterval);
    clearInterval(this.testtimerInterval);
  },
  methods: {
    Timer() {
      let timeset = Number(this.testDetails.config.testDuration) * 60;
      let timer = localStorage.getItem("timer");
      this.$store.commit("sectionVUEX/saveremaininigtesttime", timer);
      this.$store.commit("sectionVUEX/testtaken", true);
      SocketioService.socket.emit("message", "start");
      SocketioService.socket.emit("sendtime", {
        timer: timer,
        userID: this.userID,
        testID: this.testDetails.testID,
        duration: timeset,
        attemptNo: this.testDetails.attemptNo,
      });
      let last = new Date().getTime();
      this.socketInterval = setInterval(() => {
      let current = new Date().getTime();
        if (current - last > 15000) {
          window.onbeforeunload = null;
          window.location.reload();
        }
        last = current;

        this.$store.commit(
          "sectionVUEX/saveremaininigtesttime",
          this.testtimeleft
        );
        let time =
          ("0" + (Math.floor(this.testtimeleft / 3600) % 24)).slice(-2) +
          ":" +
          ("0" + (Math.floor(this.testtimeleft / 60) % 60)).slice(-2) +
          ":" +
          ("0" + (this.testtimeleft % 60)).slice(-2);
        let msg = time;
        localStorage.setItem("remainingtime", msg);
        let remainingtime = localStorage.getItem("remainingtime");
        this.$store.commit(
          "sectionVUEX/saveremaininigtesttimeintimeformat",
          remainingtime
        );
        let array = remainingtime.split(":");
        let seconds =
          parseInt(array[0], 10) * 3600 +
          parseInt(array[1], 10) * 60 +
          parseInt(array[2], 10);
        localStorage.setItem("timer", seconds);
        this.timerFix = seconds;
        this.timeinsecondsfornotification = seconds;
        if (seconds <= 0) {
          clearInterval(this.socketInterval);
          clearInterval(this.testtimerInterval);
        }
      }, 1000);

      this.testtimerInterval = setInterval(() => {
        const closeTestdt = new Date(this.testDetails.config.closeTestdt);
        const current = new Date();
        const buffer = new Date(closeTestdt.getTime() + 15 * 60000);
        if (
          this.testTimer == "00:00:00" ||
          this.testTimer == "00:00:01" ||
          current > buffer
        ) {
          this.$store.commit(
            "sectionVUEX/saveremaininigtesttimeintimeformat",
            "00:00:00"
          );
          this.timerEnd = true;
          this.force = 1;
          this.stopTestBtn();
          clearInterval(this.socketInterval);
          clearInterval(this.testtimerInterval);
        }
      }, 1000);
      setInterval(() => {
        let timer = localStorage.getItem("timer");
        let payload = {
          testID: this.testDetails.testID,
          timer: parseInt(timer),
          groupID: this.testDetails.groupID,
          attemptNo: this.testDetails.attemptNo,
        };
        this.posttimereveryminute(payload);
      }, 60000);
    },
    stopTestBtn() {
      window.onbeforeunload = null;
      this.endTestPost();
      this.stoprecord();

      setTimeout(() => {
        if (this.endTestRes) {
          if (this.testDetails.config.allowAgent) {
            this.StopAgent();
          }
          SocketioService.socket.emit("endTest", {
            userID: this.userID,
            testID: this.testID,
          });
          let data = { type: "STOP_TEST", text: "STOP_TEST" };
          window.postMessage(data, this.url);
        }
      }, 1000);
    },
    async endTestPost() {
      try {
        this.Loaderstatus = true;
        if (this.timerEnd) {
          this.force = 1;
        }
        let data = {
          topicID: this.testDetails.topicID,
          testID: this.testDetails.testID,
          groupID: this.testDetails.groupID,
          timerEnd: this.timerEnd,
          force: this.force,
          attemptNo: this.testDetails.attemptNo,
        };
        const res = await endTestPost(data);
        if (res.status == 200) {
          this.endTestRes = true;
          let payload = {};
          payload.topicID = this.testDetails.topicID;
          payload.testID = this.testDetails.testID;
          payload.groupID = this.testDetails.groupID;
          payload.candidateID = this.userID;
          payload.groupID = this.testDetails.groupID;
          payload.attemptNo = this.testDetails.attemptNo;
          const res1 = await postMarking(payload);
          this.finishTest();
          console.log(res1);
        } else if (res.status == 409) {
          this.requiredText = res.data.data.Detail;
          this.endTestRes = false;
          this.cantendtest = true;
        } else if (res.status == 406) {
          this.laterModalText = res.data.data.Detail;
          this.latermodal = true;
        }
      } catch (error) {
        this.Loaderstatus = false;
        if (error.response.status == 409) {
          this.requiredText = error.response.data.data.Detail;
          this.endTestRes = false;
          this.cantendtest = true;
        } else if (error.response.status == 406) {
          this.laterModalText = error.response.data.data.Detail;
          this.latermodal = true;
        } else {
          this.endTestRes = true;
        }
      }
    },
    stoprecord() {
      let ctestid = this.testDetails.testID;
      let cuserid = this.userID;
      let attemptNumber = this.testDetails.attemptNo;
      let cgroupid = this.testDetails.groupID;

      let types = "";
      let proctoring = false;
      if (this.testDetails.config.enableProctoring) {
        proctoring = true;
      }
      if (
        this.testDetails.config.enableCamera &&
        !this.testDetails.config.enableScreenShare
      ) {
        types = "camera";
      } else if (
        this.testDetails.config.enableScreenShare &&
        !this.testDetails.config.enableCamera
      ) {
        types = "screen";
      } else {
        types = "both";
      }

      let payload = {
        userid: cuserid,
        testid: ctestid,
        groupid: cgroupid,
        attemptNo: attemptNumber,
        licenseKey: this.licenseKey,
        time: this.testDetails.config.testDuration,
        proctor: proctoring,
        type: types,
      };
      socket.emit("forceDisconnect", payload);
    },
    async posttimereveryminute(data) {
      await posttimer(data);
    },
    StopAgent() {
      stopTestAgent();
    },
    finishtestlater() {
      this.force = 1;
      this.stopTestBtn();
    },
    finishTest() {
      localStorage.removeItem("testStarted");
      localStorage.removeItem("proctertimer");
      localStorage.setItem("testFinished", true);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        this.$router.push(
          {
            path: "/finishtest",
          },
          () => {
            this.$router.go(0);
          }
        );
      this.Loaderstatus = false;
    },
  },
};
export default testScreen;
